import * as React from "react";
import { ClickToClaimToast as SharedUiC2CToast } from "@shared-ui/retail-loyalty-click-to-claim-toast";
import { ClickToClaimToastProps } from "./typings";

export const ClickToClaimToast: React.FC<ClickToClaimToastProps> = ({ templateComponent }) =>
  !templateComponent?.metadata?.id ? null : (
    <div data-testid={templateComponent.metadata.id}>
      <SharedUiC2CToast inputs={{ offerCode: "" }} />
    </div>
  );

export default ClickToClaimToast;
